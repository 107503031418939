import React, {useEffect, useLayoutEffect, useState} from 'react';
import './App.css';
import {QueryClient, QueryClientProvider, useQuery} from '@tanstack/react-query';
import {
    Alert,
    AppBar,
    Box,
    Card,
    Container,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Toolbar,
    Typography
} from "@mui/material";

import Gantt from 'frappe-gantt';
import axios from "axios";
import {atom, useAtom, useAtomValue} from 'jotai';
import {useUpdateAtom} from "jotai/utils";
import {LoadingButton} from "@mui/lab";

const queryClient = new QueryClient();

const viewModeAtom = atom<Gantt.viewMode>('Week');
const groupByAtom = atom<'default' | 'time'>('default');
const authTokenAtom = atom<null | string>(null);
const authErrorAtom = atom(false);
const authExpiredAtom = atom(false);


const BASE_URL = 'https://od2l0ntvdl.execute-api.ap-northeast-1.amazonaws.com';


interface MyTask extends Gantt.Task {
    section: string;
}


function MainAppBar() {
    const [vm, setVm] = useAtom(viewModeAtom);
    const [groupBy, setGroupBy] = useAtom(groupByAtom);

    return (
        <AppBar>
            <Toolbar>
                <Container>
                    <Stack direction="row" alignItems="center">
                        <Typography variant="h5">Aironworks Gantt</Typography>

                        <Box sx={{flex: 1}}/>

                        <ToggleButtonGroup
                            value={groupBy}
                            sx={{mr: 2}}
                            exclusive
                            onChange={(_, v) => setGroupBy(v)}
                            size="small"
                        >
                            <ToggleButton value="default">Default</ToggleButton>
                            <ToggleButton value="time">Time</ToggleButton>
                        </ToggleButtonGroup>

                        <ToggleButtonGroup
                            value={vm}
                            sx={{mr: 2}}
                            exclusive
                            onChange={(_, v) => setVm(v)}
                            size="small"
                        >
                            <ToggleButton value="Day">Day</ToggleButton>
                            <ToggleButton value="Week">Week</ToggleButton>
                            <ToggleButton value="Month">Month</ToggleButton>
                        </ToggleButtonGroup>

                        {/*<Button disabled variant="contained" color="secondary">Edit Plan</Button>*/}
                    </Stack>
                </Container>
            </Toolbar>
        </AppBar>
    );
}


function parseDate(s: string) {
    const [year, month, day] = s.split("-");
    const result = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    return result;
}


function MainGantt() {
    const vm = useAtomValue(viewModeAtom);
    const [gantt, setGantt] = useState<null | Gantt>(null);
    useLayoutEffect(() => {
        const tasks = [
            {
                id: 'Task 1',
                name: 'Please wait...',
                start: '2016-12-28',
                end: '2016-12-31',
                progress: 20,
                dependencies: 'Task 2, Task 3',
                custom_class: 'pasten',
            }
        ];

        const options = {
            view_mode: vm,
        };

        setGantt(new Gantt("#gantt", tasks, options as Gantt.Options));
    }, []);

    const [authToken, setAuthToken] = useAtom(authTokenAtom);
    const setAuthExpired = useUpdateAtom(authExpiredAtom);
    const {data} = useQuery(['gantt'], async () => await axios.get(BASE_URL + '/api/gantt', {
        headers: {
            'Authorization': 'Bearer ' + authToken
        }
    }), {
        refetchInterval: 5000,
        refetchOnMount: true,
        retry: false,
        onError(error) {
            setAuthToken(null);
            setAuthExpired(true);
        }
    });
    const [ganttTime, setGanttTime] = useState(0);
    const [ganttTasks, setGanttTasks] = useState<MyTask[]>([]);

    useEffect(() => {
        const newTasks = data?.data?.gantt?.tasks;
        if (!newTasks)
            return;

        if (gantt && data.data.gantt.update_time !== ganttTime) {
            setGanttTime(data.data.gantt.update_time);
            const resultTasks = newTasks.map((task: Gantt.Task) => ({
                ...task,
                custom_class: (task as any).section.toLowerCase().replaceAll(' ', '_').replaceAll('-', '').replaceAll('?', 'any'),
                progress: 0,
            }));
            setGanttTasks(resultTasks);
            gantt.refresh(resultTasks);
        }
    }, [data, gantt]);

    useEffect(() => {
        if (gantt)
            gantt.change_view_mode(vm);
    }, [vm, gantt]);

    const groupBy = useAtomValue(groupByAtom);
    useEffect(() => {
        if (gantt && ganttTasks.length > 0) {
            const newTasks = [...ganttTasks];
            newTasks.sort((a, b) => {
                const sa = parseDate(a.start);
                const sb = parseDate(b.start);
                if (groupBy === 'default') {
                    return a.section.localeCompare(b.section) || +(sa > sb) - +(sa < sb);
                } else if (groupBy === 'time') {
                    return +(sa > sb) - +(sa < sb);
                }
                return 0;
            });

            gantt.refresh(newTasks);
        }
    }, [groupBy, gantt])

    return (
        <Box sx={{height: 'calc(100vh - 64px)', mt: '64px', position: 'relative'}}>
            <svg id="gantt"/>
        </Box>
    );
}


function LoginPage() {
    // const login = useMutation(async ({ password }) => axios.post(BASE_URL + '/api/login', {password,}), {
    //     onSuccess(data) {
    //         console.log('LOGIN', data);
    //     }
    // });

    const setAuthToken = useUpdateAtom(authTokenAtom);
    const [authLoading, setAuthLoading] = useState(false);
    const [authError, setAuthError] = useAtom(authErrorAtom);
    const [authExpired, setAuthExpired] = useAtom(authExpiredAtom);
    const [password, setPassword] = useState("");
    const handleLogin = async () => {
        setAuthLoading(true);

        try {
            const response = await axios.post(BASE_URL + '/api/login', {password});
            if (response.status === 200) {
                if (!response.data?.success) {
                    setAuthError(true);
                }

                if (response.data?.token) {
                    setAuthToken(response.data.token);
                    setAuthError(false);
                    setAuthExpired(false);
                }
            }
        } finally {
            setAuthLoading(false);
        }
    };

    return (
        <Box sx={{height: '100vh', bgcolor: '#eee'}}>
            <Stack sx={{height: '100%'}} justifyContent="center" alignItems="center">
                <Card variant="elevation" sx={{p: 3}}>
                    <Stack spacing={2} sx={{width: 400}}>
                        {authError && (
                            <Alert severity="error">
                                Invalid credentials, please try again.
                            </Alert>
                        )}

                        {authExpired && (
                            <Alert severity="warning">
                                Your session has expired, please log in again.
                            </Alert>
                        )}

                        <TextField
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            autoFocus
                            error={authError}
                        />

                        <LoadingButton variant="contained" onClick={handleLogin} loading={authLoading}>
                            Login
                        </LoadingButton>
                    </Stack>
                </Card>
            </Stack>
        </Box>
    );
}


function App() {
    const authToken = useAtomValue(authTokenAtom);

    return (
        <QueryClientProvider client={queryClient}>
            <div className="App">

                {authToken ? (
                    <>
                        <MainAppBar/>
                        <MainGantt/>
                    </>
                ) : (
                    <LoginPage/>
                )}

            </div>
        </QueryClientProvider>
    );
}

export default App;
